<template>
  <div>
    <DeniedView v-if="decisionDenied" />
    <SupplementingView v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import { constants } from '@/mixins';
import { SupplementingView, DeniedView } from './decision';

export default {
  name: 'Denied',
  mixins: [constants],

  components: {
    SupplementingView,
    DeniedView,
  },
  computed: {
    ...mapState({
      decision: (state) => state.resolution.decision,
    }),
    decisionDenied() {
      return this.decision === 'DENIED';
    },
  },
};
</script>
