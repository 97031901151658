<template>
  <div class="decision-denied">
    <Container>
      <Card padding="large">
        <b-row>
          <b-col cols="12" sm="auto" class="text-center">
            <CloseSVG class="icon h1 text-error" />
          </b-col>
          <b-col>
            <Margins>
              <h1 class="h3 text-normal">{{ $t('SORRY_BUT_DECLINED') }}</h1>
              <ul v-for="(reason, index) in reasons" :key="index">
                <li class="text-small">{{ reason }}.</li>
              </ul>
              <Separator size="large" />
              <div class="text-center">
                <Button @click="handleSubmit">
                  {{ $t('CHECK_YOUR_DATA') }}
                </Button>
              </div>
            </Margins>
          </b-col>
        </b-row>
      </Card>
    </Container>
  </div>
</template>

<script>
import { Container, Card, Margins, Button, Separator } from '@/components';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import { constants } from '@/mixins';
import { mapState } from 'vuex';

export default {
  name: 'SupplementingView',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    Separator,
    CloseSVG,
  },

  methods: {
    handleSubmit() {
      return this.$router.push({ name: this.ROUTES.LOAN_APPLICATION.name });
    },
  },

  computed: {
    ...mapState({
      reasons: (state) => state.resolution.rejectionReasons,
    }),
  },
};
</script>
